.InfoEmpresa{
  .tituloServicio{
    font-size: 2rem !important;
  }

  .titulo{
    font-size: 20px !important;
  }

  .texto{
    font-size: 16px !important;
  }

  .swiper-button-next, .swiper-button-prev{
    color: #273c50 ;
  }

  /* Estilos para los dots de navegación */
  .carousel-dots-noche, .carousel-dots-dia {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Ajusta el margen según sea necesario */
  }

  .carousel-dots-dia .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #888; /* Color de los dots inactivos */
    margin: 0 5px; /* Espaciado entre los dots */
    cursor: pointer;
  }

  .carousel-dots-dia .swiper-pagination-bullet-active {
    background-color: #333; /* Color del dot activo */
  }  

  .carousel-dots-noche .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff; /* Color de los dots inactivos */
    margin: 0 5px; /* Espaciado entre los dots */
    cursor: pointer;
  }

  .carousel-dots-noche .swiper-pagination-bullet-active {
    background-color: #fff; /* Color del dot activo */
  }  

  .historia{
    background-color: #254153;
    color: #ffffff;
  }

  .linea-vertical{
    display: flex;
    border-left: 3px solid white; /* Puedes personalizar el grosor y el color de la línea */
    height: 100%; 
  }
}