.Oferta{
  .fondoGris{
    background-color: #888585d5;
    border-radius: 7px;
  }

  .link{
    text-decoration: none;       
  }

  .botonColor{
    background-color: #505F6D;
  }

  .botonColor:hover{
    background-color: #273C50 !important;
  }

  .texto{
    padding-right: 2rem;
  }

  .texto p{
    font-size: 0.9rem !important;
    text-align: justify;
  }

  h1{
    font-size: 2.5rem !important;
  }
}