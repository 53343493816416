.aglobiq {
  .infoAglobriq{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .textInfo{
    padding-right: 2rem;
  }

  .imageInfo{
    width: 85%;
  }

  .producto {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p{
    text-align: justify;
  }

  .list-group-item{
    color: unset;
    div{
      text-align: start;
    }
  }  

  table {
    font-size: 1.2em;
    font-family: 'Assistant', sans-serif !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    --bs-table-striped-bg: #D3D3D3;
  }

  thead tr th{
    background-color: #525258 !important;
    color: #ffffff;
    text-align: left;
  }

  tbody tr td{
    text-align: left;
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid #525258;
  }

  @media only screen and (max-width:767px){
    .Fundamento .capa-superior{
      top: 0%;
    }
  } 
}

.heroAglobriq img{
  position: absolute;
  width: 100%;
  height: 95vh;
  object-fit: cover;
}

.heroAglobriq canvas{
  opacity: .7;
  width: 100%;
  height: 95vh;
}
