.Servicios{

  @media (min-width:1400px){
    .fondo{
      width: 420px !important;
    }
  }

  @media (min-width:1200px) and (max-width:1399px){
    .fondo{
      width: 360px !important;
    }
  }

  @media (min-width:992px) and (max-width:1199px){
    .fondo{
      width: 300px !important;
    }

    .fondo figcaption{
      height: 9rem !important;
    }

    .fondo:hover figcaption{
      top: 80px !important;
    }
  }

  @media (min-width:768px) and (max-width:991px){
    .fondo{
      width: 340px !important;
    }

    .fondo figcaption{
      height: 8rem !important;
    }
  }

  @media (min-width:540px) and (max-width:767px){
    .fondo{
      width: 250px !important;
    }
    .fondo .titulo{
      font-size: 1rem !important;
    }
    .fondo .texto{
      font-size: 0.8rem !important;
    }
    .fondo:hover figcaption{
      top: 80px !important;
    }
    .fondo figcaption{
      height: 7rem !important;
    }
  }

  @media (min-width:300px) and (max-width:539px){
    .fondos{
      display: flex;
      justify-content: center !important;
    }

    .fondo{
      max-width: 100% !important;
    }

    .fondo:hover figcaption{
      top: 80px !important;
    }

    .fondo figcaption{
      height: 9rem !important;
    }
  }

  @media (max-width:299px){
    .fondo .titulo{
      font-size: 1.1rem !important;
    }

    .fondo:hover figcaption{
      top: 80px !important;
    }
  }

  .servicio_boton {
    color: #ffffff;
    background-color: transparent;
    border: 0;
    width: 50%;
    text-align: left;
    padding: 0;
    opacity: 0;
  }

  .fondo {
    position: relative;
    overflow: hidden;
    line-height: 1.4rem;
    border: 1px solid rgba(0, 0, 0, 0.175);
    height: 14rem;
  }
  
  .fondo * {
    transition: all 0.35s ease;
  }

  .fondo figcaption {
    position: absolute;
    top: calc(77%);
    padding-left: 8px;
    padding-right: 8px;
    height: 8rem;
    width: 100%;
    background-color: #36628d;
  }
  
  .fondo figcaption:before {
    position: absolute;
    content: '';
    bottom: 100%;
    width: 100%;
    height: 80px;
    background-image: -webkit-linear-gradient(top, transparent 0%, #36628d 100%);
    background-image: linear-gradient(to bottom, transparent 0%, #36628d 100%);
  }
  
  .fondo .titulo {
    font-weight: 300;
    font-size: 1.2rem;
    color: #ffffff;
  }

  .fondo .texto {
    font-size: 0.9rem;
    letter-spacing: 1px;
    opacity: 0;
    color: #ffffff;
  }

  .fondo:hover figcaption,
  .fondo.hover figcaption {
    cursor: pointer;
    top: 100px;
    .texto{
      opacity: 1;
    }
    .servicio_boton{
      opacity: 1;
    }
  }
  
}