.Teamwork {
  h3,
  h4 {
    font-family: "Raleway", sans-serif;
  }

  h3 {
    font-size: 1.4em;
    font-weight: 400;
  }

  h4 {
    font-size: 1em;
    font-weight: 300;
  }

  .imgAlt {
    position: relative;
    opacity: 0;
    border-radius: 7px;
  }

  .imgOriginal {
    position: absolute;
    border-radius: 7px;
  }

  .texto {
    width: 200px;
  }

  .img:hover {    
    .imgAlt {
      opacity: 1;
      transform: scale(1.2);
      transition: transform 0.4s;
      cursor: pointer;
    }
    .imgOriginal {
      opacity: 0;
    }
    .nombre {
      transform: translateY(1.2rem);
    }
    .texto {
      transform: translateY(1.2rem);
    }
  }
}

@media (max-width: 575px) {
  .Teamwork {
    text-align: center;
  }

  .img.col {
    text-align: -webkit-center;
  }
}

.modalTeam {
  .modal-content{
    background-color: #11111e !important;

    .modalColor{
      background-image: url("../../assets/images/fondos/fondoModal.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .titulo {
      color: #abcde8;
    }
  
    .lema {
      background: rgba(171, 205, 232, 0.7);
    }
  
    .text-justify {
      text-align: justify;
    }
  
    .nombreModal{
      text-transform: capitalize;
      font-weight: bold;
      font-size: 36px !important;
    }
  }
}
