.infoServicios{
  .analisis{
    background-image: url("../../assets/images/servicios/analisis1.jpg");
    background-size: cover;
    min-height: 650px;
    width: 100%;
    display: flex;
  }

  .mineral{
    background-image: url("../../assets/images/servicios/mineral1.jpg");
    background-size: cover;
    min-height: 650px;
    display: flex;
  }

  .preparacion{
    background-image: url("../../assets/images/servicios/preparacion1.jpg");
    background-size: cover;
    min-height: 650px;
    display: flex;
  }

  .investigacion{
    background-image: url("../../assets/images/servicios/proceso1.jpg");
    background-size: cover;
    min-height: 650px;
    display: flex;
  }

  .distribucion{
    background-image: url("../../assets/images/servicios/heroTarjetas1.jpg");
    background-size: cover;
    min-height: 650px;
    display: flex;
  }

  .aseguramiento{
    background-image: url("../../assets/images/servicios/qaqc1.jpg");
    background-size: cover;
    min-height: 650px;
    display: flex;
  }

  .textoServicios{
    background-color: rgba(0, 0, 0, 0.7);
    color: #FFF;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .textoServicios .row a{
    text-align: start;
  }

  .alturaServicios{
    height: 100%;
  }
}