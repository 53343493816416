@import "../variables.scss";

.row {
  --bs-gutter-x: 0 !important;
}

.labLogo {
  width: 30% !important;
  height: 90% !important;
}

@media (max-width: 1200px) {
  .labLogo {
    width: 50% !important;
    height: 90% !important;
  }
}

@media (max-width: 768px) {
  .labLogo {
    width: 80% !important;
    height: 80% !important;
  }
}

@media (max-width: 576px) {
  .labLogo {
    width: 50% !important;
    height: 50% !important;
  }
  .logo {
    justify-content: center !important;
  }
  .footer {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .col-6 {
    width: 100% !important;
  }
}

@media (max-width: 300px) {
  .labLogo {
    width: 100% !important;
    height: 100% !important;
  }
  .footer {
    display: flex !important;
    align-items: flex-start;
  }
}

@media (max-width: 1199px) {
  .col-lg-12 {
    flex: 0 0 auto;
  }
  .centrado {
    text-align: center;
  }
}
