.navmenu {
  color: white;
  background-color: transparent !important;
  opacity: 1;

  .logo{
    width: 175px;
  }

  .servicio{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.55);
  }

  .servicio:hover{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
  }
  
  &__nav-items {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
  }
}

.navmenu.scroll {
  background-color: #273c50 !important;
}

.hero{
  margin-bottom: -2%;
}

.hero video{
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.canvas{
  opacity: .7;
  width: 100%;
  height: 100vh;
}