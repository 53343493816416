.TitleDerecha{
  position: relative;
  overflow: hidden;

  .capa-superior {
    position: absolute;
    top: 35%;
  }  

  .capa-inferior{
    transform: matrix(1, 0, 0.78129, 1, 50, 0); 
    width: 65%;
    background-color:#273c50;
    padding:60px 0 60px;
    float: right;
  }
  
  .anonce-block__parts{
    position:absolute;
    top:0;
    right: calc(62% + 15px);
    height:100%;
    font-size:0;
    direction: rtl;
  }
  
  .anonce-block__part:nth-child(1){
    max-width:540px;
    position:absolute;
    height:100%;
    z-index:10;
    right:-6px;
    background:#505F6D;
    top:0;
    transform:skewX(38deg);
    width:145px
  }
  
  .anonce-block__part:nth-child(2){
    position:absolute;
    height:100%;
    z-index:10;
    right:138px;
    background:#918F8F;
    top:0;
    transform:skewX(38deg);
    width:95px
  }
  
  .anonce-block__part:nth-child(3){
    position:absolute;
    height:100%;
    z-index:10;
    right:232px;
    background:#918F8F;
    top:0;
    transform:skewX(38deg);
    width:68px;
  }
  
  .anonce-block__body{
    max-width:60%;
    float: right;
  }
  
  .anonce-block__title{
    color:#fff;
    font-size:28px;
    letter-spacing:.4px;
  }
  
  .anonce-block__cicles{
    width:200px;
    height:200px;
    position:absolute;
    border-radius:50%;
    right:-103px;
    top:50%;
    transform:translateY(-50%)
  }
  
  .anonce-block__cicles:before{
    content:'';
    position:absolute;
    width:34px;
    height:34px;
    background-color:#fff;
    border-radius:50%;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%)
  }
  
  .anonce-block__cicle:nth-child(1){
    position:absolute;
    width:75px;
    height:75px;
    border:1px solid rgba(255,255,255,.4);
    background:#fff;
    border-radius:50%;
    left:50%;
    top:50%;
    transition:background 1s;
    transform:translate(-50%,-50%)
  }
  
  .anonce-block__cicle:nth-child(2){
    position:absolute;
    width:130px;
    height:130px;
    border:1px solid rgba(255,255,255,.5);
    background:#fff;
    border-radius:50%;
    left:50%;
    top:50%;
    transition:background 2s;
    transform:translate(-50%,-50%)
  }
  
  .anonce-block__cicle:nth-child(3){
    position:absolute;
    width:190px;
    height:190px;
    border:1px solid rgba(255,255,255,.7);
    background:#fff;
    border-radius:50%;
    left:50%;
    top:50%;
    transition:background 3s;
    transform:translate(-50%,-50%)
  }

  @media only screen and (max-width:1300px){
    .anonce-block__part:nth-child(1){
      left:-8px
    }
    .anonce-block__part:nth-child(2){
      left:136px
    }
    .anonce-block__part:nth-child(3){
      left:230px
    }
  }

  @media (min-width:650px) and (max-width:1299px){
    .capa-inferior{
      width: 67%;
    }

    .anonce-block__title{
      font-size: 24px;
    }
  }  

  @media (min-width:530px) and (max-width:649px){
    .capa-inferior{
      width: 80%;
    }

    .anonce-block__title{
      font-size: 24px;
    }

    .anonce-block__body{
      margin-right:20px!important;   
    }
  }  

  @media (min-width:370px) and (max-width:529px){
    .anonce-block__part:nth-child(1){
      right: 50px;
    }

    .anonce-block__body{
      margin-right:20px!important;   
    }

    .capa-inferior{
      width: 100%;
    }

    .anonce-block__body{
      max-width:85% !important
    }

    .anonce-block__title{
      font-size: 20px;
    }

    .capa-superior{
      top: 25%;
    }
  } 

  @media (max-width:369px){  
    .anonce-block__body{
      margin-right:20px!important;   
    }

    .capa-inferior{
      width: 120%;
    }

    .anonce-block__title{
      font-size: 16px;
    }
  } 
}