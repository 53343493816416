.backgroundRock {
  background-image: url("assets/images/fondos/backgroundOferta.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.backgroundRockNoche {
  background-image: url("assets/images/fondos/backgroundOfertaNoche.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.App {
  text-align: center;
}