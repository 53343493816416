.Logro{
  text-align: center;
  .cuerpo{
    display: grid;
    align-items: end;
    background-color: transparent;
  }

  .border-0{
    background-color: transparent !important;    
  }
  
  .titulo-dia {
    font-size: 1.5rem !important;
    color: #273c50;
  }

  .texto-dia{
    font-size: 2.5rem !important;
    color: #273c50;
  }

  .titulo-noche {
    font-size: 1.5rem !important;
    color: #4f81b1;
  }

  .texto-noche{
    font-size: 2.5rem !important;
    color: #4f81b1;
  }

  @media only screen and (min-width:1400px){
    .titulo-dia, .titulo-noche{
      font-size: 1.8rem !important;
    }
  } 
}