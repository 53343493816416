.navmenu {
  color: white;
  background-color: rgba(39, 60, 80, var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 1;

  .logo{
    width: 175px;
  }
}

div[aria-labelledby="basic-nav-dropdown"] {
  left: 35px !important;
}

.navmenu {
  &__nav-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
